/** fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Nunito+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
/* @font-face {
    font-family: 'Montserrat';
    src: url('../public/assets/fonts/Montserrat-Regular.eot');
    src: url('../public/assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../public/assets/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../public/assets/fonts/Montserrat-Regular.woff') format('woff'),
        url('../public/assets/fonts/Montserrat-Regular.ttf') format('truetype'),
        url('../public/assets/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} */
/** variables */
:root{
  --primary: #003366;
  --secondary: #F8CA0F;

  --secondary-hover-1: #DAB106;
  --secondary-hover-2: #CEA706;
  --font-1: #1F1212;
  --font-2: #7C7C7C;
  --nav-height-min: 67.56px;
  --nav-height: 84px;
}

/** base styles */
html,
body,main {
  padding: 0px;
  margin: 0px;
}

main {
}

p,a,ul,li {
  margin: 0px;
  padding: 0px;
  color: inherit;
  text-decoration: none;
  border: none;
}

a:hover {
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

body {
  /* font-family: 'Montserrat'; */
  font-family: 'Nunito Sans', "Inter";
}

.mobile {
  display: inline;
}

.desktop {
  display: none;
}

.container {
  width: 100%;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.bg-white {
  background-color: #FFFFFF;
}

.justify-center {
  justify-content: center;
  text-align: center;
}

button {
  background-color: var(--secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  color: #003366;
  width: 92px;
  height: 40px;
  padding: 8px 12px;
  font-size: 14px;
  letter-spacing: 2%;
  font-weight: 700;
  cursor: pointer;
  font-family: 'Nunito Sans';
  border: none;
  line-height: 24px;
}

a:hover > button {
  text-decoration: none;
}

button.free {
  width: 100%;
}

button.clear {
  color: var(--secondary);
  border: 1px solid var(--secondary);
  background-color: transparent;
}

button:hover {
  background-color: var(--secondary-hover-1);
  border-color: var(--secondary-hover-2);
  text-decoration: none !important;
}

button.clear:hover {
  background-color: transparent;
}

li {
  list-style-type: none;
}

h1,h2,h3,h4 {
  font-weight: normal;
  line-height: 1.4em;
  /* font-family: 'Montserrat'; */
  font-family: 'Nunito Sans';
}

p,a,li {
  line-height: 1.5em;
  font-size: 1em;
  /* font-family: 'Montserrat'; */
  font-family: 'Inter';
}

h3,h4 {
  font-size: 1.1em;
}
h2 {
  font-size: 1.2em;
}
h1 {
  font-size: 1.6em;
}

.none {
  display: none;
}

.semi_bold {
  font-weight: 500;
}

/** animation */
@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@-o-keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in-half {
  0% {
    /* opacity: 0; */
    transform: translateX(10%);
  }
  100% {
    /* opacity: 1; */
    transform: translateX(0%);
  }
}

@-moz-keyframes slide-in-half {
  0% {
    /* opacity: 0; */
    transform: translateX(10%);
  }
  100% {
    /* opacity: 1; */
    transform: translateX(0%);
  }
}

@-o-keyframes slide-in-half {
  0% {
    /* opacity: 0; */
    transform: translateX(10%);
  }
  100% {
    /* opacity: 1; */
    transform: translateX(0%);
  }
}

@keyframes slide-in-half {
  0% {
    /* opacity: 0; */
    transform: translateX(10%);
  }
  100% {
    /* opacity: 1; */
    transform: translateX(0%);
  }
}

.ease-in {
  /* -webkit-animation-name: slide-in;
  -webkit-animation-duration: .5s;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-iteration-count: 1;
  animation-name: slide-in;
  animation-duration: .5s ;
  animation-timing-function: ease-out;
  animation-iteration-count: 1; */

  display: inline-block;
  animation-iteration-count: 1;
  animation-delay: .1s;
  animation: slide-in 1s;
}

.slide-in {
  display: inline-block;
  animation-iteration-count: 1;
  animation-delay: .1s;
  animation: slide-in-half 1s;
  transition-timing-function: linear;
}

/** prevent unnecessary DOM jumps when updating DOM */
* {
  overflow-anchor: none !important;
  scroll-snap-stop: normal !important;
  overscroll-behavior: unset !important;
  scroll-behavior: unset !important;
}

.container, header, footer, .container-fluid, body, div, span, section {
  overflow-anchor: none !important;
  scroll-snap-stop: normal !important;
  overscroll-behavior: unset !important;
  scroll-behavior: unset !important;
}

/** mobile first styles about bigger than 395px width */
.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.bg-white {
  background-color: #FFFFFF;
}

.subheader {
  font-size: 1.25em;
  font-weight: 600;
  line-height: 32px;
}

.subheader_1 {
  font-size: 1.125em;
  font-weight: 600;
  line-height: 24px;
}

.subheader_2 {
  font-size: 1.125em;
  font-weight: 600;
  line-height: 24px;
}

.subheader_3 {
  /* font-size: 1.25em; */
  font-size: 20px;
  font-style: normal;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0em;
}

.subheader_4 {
  /* font-size: 24px; */
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
}

.text_1 {
  font-style: normal;
  font-weight: 600;
  /* line-height: 32px; */
  text-align: left;

  font-size: .88em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.justify-center {
  text-align: center;
  justify-content: center;
}


/** very small phones (galaxy fold) */
@media screen and (max-width: 285px){
  .container {
    padding: 0;
  }

  button {
    font-size: 1.34em;
  }

  .subheader {
    font-size: 3.34em;
  }

  .subheader_1 {
    font-size: 1.79em;
  }

  .subheader_3 {
    font-size: 1.56em;
  }

  .text_1 {
    font-size: 1.156em;
  }

  .subheader_2 {
    font-size: 1em;
  }

  .subheader_4 {
  /* font-size: 1.25em; */
    font-size: 1.67em;
  }
}

/** small phones styles */
@media screen and (max-width: 395px){
  body {
    font-size: 12px;
  }
  
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }

  button {
    padding: 10px 10px;
    font-size: 1.34em;
  }

  .subheader {
    font-size: 2em;
  }

  .subheader_1 {
    font-size: 1.34em;
  }

  .subheader_2 {
    font-size: 1.5em;
  }

  .subheader_3 {
    font-size: 1.67em;
  }

  .subheader_4 {
    font-size: 1.67em;
  }

  .text_1 {
    font-size: 1.167em;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
  
  button {
    line-height: 20px;
  }
}

/** mini tab styles */
@media screen and (min-width: 768px){
  .container {
    max-width: 720px;
  }
  .text_1 {
    font-size: .88em;
  }
}

@media screen and (max-width: 768px){
  @keyframes backandforth {
    0% {
      transform: translateX(-0%);
    }
    100% {
      transform: translateX(-85.8%);
    }
  }
}

@media screen and (min-width: 769px){
  @keyframes backandforth {
    0% {
      transform: translateX(-0%);
    }
    100% {
      transform: translateX(-151.5%);
    }
  }
}

@media screen and (max-width: 991px){
  img.clients-0, img.clients-1 {
      height: 38px !important;
  }
  img.clients-6, img.clients-9, img.clients-11, img.clients-12 {
      height: 26px !important;
  }
  img.clients-7 {
    height: 16.67px !important;
  }
}

/** tab styles */
@media screen and (min-width: 992px){
  .mobile {
    display: none;
  }

  .desktop {
    display: inline;
  }

  .container {
    max-width: 960px;
  }

  .grid {
    grid-template-columns: repeat(12, 1fr);
  }


  button {
    width: 174px;
    height: 52px;
    /* padding: 14px 20px; */
    line-height: 24px;
  }

  .subheader_1 {
    line-height: 32px;
  }

  .subheader_2 {
    font-size: 1em;
    font-weight: 600;
    line-height: 24px;
  }

  .subheader {
    font-size: 2em;
    line-height: 48px;
    font-weight: 700;
  }

  .main-layout {
    /* margin-top: var(--nav-height); */
    margin-top: calc(var(--nav-height) + 29px);
  }

  .subheader_3 {
    /* font-size: 2em;
    line-height: 48px; */
    font-size: 28px;
    line-height: 40px;
  }

  .subheader_4 {
    font-size: 1.5em;
    line-height: 32px;
  }

  .text_1 {
    font-size: 1.25em;
    font-weight: 600;
    line-height: 32px;
  }
  
  img.clients-0, img.clients-1, img.clients-9, img.clients-11, img.clients-12 {
    height: 50px !important;
  }
  
  img.clients-6 {
    height: 36px !important;
  }
  
  img.clients-7 {
    height: 24px !important;
  }
}

/** desktop styles */
@media screen and (min-width: 1200px){
  body{
    font-size: 16px;
  }
  
  .container {
    max-width: 1140px;
  }
}

.backandforth {
  animation: backandforth 60s linear 0s infinite;
}